import { BaseService } from "../base.service";
import { ErrorWrapper, ResponseWrapper } from "../util";

export class DBService extends BaseService {
    static get entity() {
        return "keyword"
    }

    static apiClient(options) {
        const baseURL = process.env.VUE_APP_API_URL;
        return this.initApiClient({ baseURL, ...options });
    }

    static async seed(params = {}) {
        try {
            const response = await this.apiClient().get('db/seed', { params });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getArticleTagsSearch(params = {}) {
        try {
            const response = await this.apiClient().get('article_tags/search', { params });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getArticleTagsArticles(params = {}) {
        try {
            const response = await this.apiClient().get('article_tags/articles', { params });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getTagsMissing(params = {}) {
        try {
            const response = await this.apiClient().get('article_tags/missing', { params });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getTagsMissingFiltered(params = {}) {
        try {
            const response = await this.apiClient().get('article_tags/missing/filtered', { params });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getExistingTags(params = {}) {
        try {
            const response = await this.apiClient().get('article_tags/existing', { params });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }


}