<template>
  <b-col class="d-flex justify-content-end">
    Total: {{ pagination.total }}
    <br />
    Last checked: {{ lastChecked }}
    <br />
    Next scheduled: in
    {{ nextSheduledIn() }}
    days
  </b-col>
</template>

<script>
export default {
  name: "Info",
  props: {
    pagination: {
      type: Object,
      default: () => {},
    },
    lastChecked: {
      type: String,
      default: () => "",
    },
  },
  methods: {
    updateData() {
      this.$parent.updateData();
    },
    nextSheduledIn() {
      return parseInt(
        (new Date().setDate(
          new Date().getDate() + ((4 + 7 - new Date().getDay()) % 7 || 7)
        ) -
          new Date()) /
          (1000 * 60 * 60 * 24)
      );
    },
  },
};
</script>
