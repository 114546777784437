<template>
  <div>
    <h2 class="text-center my-4">DB</h2>
    <b-row class="mx-2">
      <Info :pagination="pagination" :lastChecked="lastChecked" updateData="updateData" />
    </b-row>
    <b-button squared size="sm" @click="seed">seed</b-button>
  </div>
</template>

<script>
import { DBService } from "../services/db/db.service";
import Info from "../components/Info.vue";

export default {
  name: "DB",
  components: {
    Info,
  },
  data() {
    return {
      isBusy: false,

      pagination: {
        perPage: 25,
        currentPage: 1,
        lastPage: 10000,
        tolal: 0,
      },

      lastChecked: "",

    };
  },
  async beforeMount() {
    await this.seed();
  },

  methods: {
    async seed() {
      this.isBusy = true;
      await DBService.seed();

      this.isBusy = false;
      return;
    },
  },
};
</script>
